@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --color-black: #010101;
  --color-white: #f3f3f3;
  --color-text: #444;

  --color-primary-light: #ffe4ae;
  --color-primary: #fed27a;
  --color-primary-dark: #feb420;

  --color-smoky-black: #161007;

  --color-secondary-light: #203354;
  --color-secondary: #192841;
  --color-secondary-dark: #152238;

  --gradient-primary: linear-gradient(to top left, #fed27a, #ffe4ae);
  --gradient-dark: linear-gradient(to right, #aec9ff, #ffe4ae);
  --gradient-primary-op: linear-gradient(
    to top left,
    rgba(254, 210, 122, 0.12),
    rgba(255, 228, 174, 0.12)
  );
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: var(--color-text);
  background-color: var(--color-white);
  animation: fadeIn 1s ease-in;
  animation-fill-mode: backwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

img {
  width: 100%;
  display: block;
}

.containerWrapper {
  max-width: 120rem;
  margin: 0 auto;
}

.btnPrimary {
  text-decoration: none;
  display: inline-block;
  background-color: #262626;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem 2rem;
  font-family: inherit;
  color: var(--color-white);
  font-size: 1.4rem;
  cursor: pointer;
  transition: all 0.2s;
}
.btnPrimary:hover {
  background-color: rgba(38, 38, 38, 0.8);
}

.contactBarWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1rem 0;
}
.contactBarItem {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.contactBarIcon {
  height: 24px;
  width: 24px;
  color: currentColor;
}
.contactBarLink:link,
.contactBarLink:visited {
  text-decoration: none;
  color: inherit;
  font-weight: 400;
  font-size: 1.6rem;

  transition: all 0.1s;
}
.contactBarLink:hover,
.contactBarLink:active {
  color: var(--color-primary-dark);
}

.heroWrapper {
  min-height: 75vh;
  background-size: cover;
  background-position-y: 15%;

  position: relative;
}

.navigationWrapper {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  color: var(--color-white);
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  padding: 1rem 2rem;

  opacity: 0.7;
  transition: all 0.5s;
}
.navigationWrapper:hover {
  opacity: 1;
  background-color: var(--color-white);
}
.navigationWrapper:hover > * {
  color: var(--color-text);
}
.navigationWrapper:hover .navigationButton {
  text-shadow: none;
}
.navigationLogo {
  max-width: 15rem;

  transition: all 0.6s;
}
.navigationLogo:hover {
  opacity: 1;
  animation: bounce 1s;
}
@keyframes bounce {
  25% {
    rotate: 7deg;
  }
  65% {
    rotate: -7deg;
  }
}
.navigationHeading {
  font-size: 2.8rem;
  font-weight: 900;
  text-transform: uppercase;
  font-style: italic;
  margin-left: 2rem;
  margin-right: auto;
  user-select: none;
  letter-spacing: 0.75px;

  transition: all 0.6s;
}
.navigationNav {
  margin-right: 4rem;
}
.navigationList {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  list-style: none;

  position: relative;
}
.navigationItem {
  font-size: 1.6rem;
}
.navigationButton:link,
.navigationButton:visited,
.navigationButton {
  white-space: nowrap;
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
  background-color: inherit;
  border: none;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);

  transition: all 0.1s;
  cursor: pointer;
}
.navigationButton:hover {
  color: var(--color-primary-dark);
}
.navButtonFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigationButtonIcon {
  height: 16px;
  width: 16px;
  color: currentColor;
  transform: translateY(2px);

  transition: rotate 0.3s, translate 0.2s;
}
.navigationDropdown {
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 3rem;
  list-style: disc;
  font-size: 1.4rem;
  font-weight: 500;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  transition: all 0.5s;
}
.navigationDropdownOpen {
  transform: translateX(-50%) translateY(0);
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.rotate {
  rotate: -180deg;
  transform: translateY(-2px);
}

.heroHeading {
  font-size: 6rem;
  font-weight: 700;
  color: var(--color-white);
  opacity: 0.9;
  padding: 8rem 4rem 4rem 8rem;
  user-select: none;
  width: 80%;
  letter-spacing: 0.5px;
}
.heroButton,
.heroButton:link,
.heroButton:visited {
  text-decoration: none;
  display: inline-block;
  background-color: inherit;
  border: 1px solid rgba(243, 243, 243, 0.8);
  color: var(--color-white);
  padding: 2rem 5rem;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 300;
  opacity: 0.8;
  cursor: pointer;

  position: absolute;
  bottom: 3rem;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  transition: all 0.2s;
}
.heroButton:hover {
  color: rgba(254, 180, 32, 0.644);
  border: 1px solid rgba(254, 180, 32, 0.616);
  transform: scale(1.02) translate(-49%, -51%);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.3);
}
.heroImgBar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.heroImgBarButton,
.heroImgBarButton:link,
.heroImgBarButton:visited {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20rem;
  min-width: 33.333vw;
  /* border: 0.5px solid rgba(243, 243, 243, 0.1); */
  border: none;
  background-size: cover;
  background-position: center;
  cursor: pointer;

  color: var(--color-white);
  font-family: inherit;
  font-weight: 700;
  font-size: 2rem;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  letter-spacing: 0.5px;
  overflow: hidden;
  transition: all 0.3s;

  position: relative;
}
.heroImgBarButton::after {
  content: "\2193";
  color: var(--color-primary-dark);
  opacity: 0.8;
  font-size: 5rem;
  font-weight: 900;
  display: flex;
  justify-content: center;
  padding-bottom: 2.5rem;
  align-items: flex-end;
  position: absolute;
  top: -100%;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s;
}
.heroImgBarButton:hover::after {
  top: 0;
}

.introWrapper {
  display: flex;
  justify-content: space-around;
  margin: 12rem 2rem;
  padding-bottom: 12rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.introHeadingBox {
  flex: 1 0 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.introHeadingPrimary {
  font-size: 2.4rem;
  color: var(--color-primary-dark);
  font-weight: 800;
}
.introHeading {
  font-size: 3.2rem;
  color: var(--color-text);
  font-weight: 900;
}
.introHeadingSecondary {
  font-size: 2rem;
  color: var(--color-text);
  font-weight: 800;
  margin-top: 4rem;
}
.introParagraph {
  padding: 0 2rem;
  font-size: 1.8rem;
  color: var(--color-text);
  line-height: 1.7;
}
.introButton,
.introButton:link,
.introButton:visited {
  align-self: flex-start;
  margin-top: auto;
}

.servicesHeading {
  font-size: 4rem;
  font-weight: 800;
  color: var(--color-text);
  text-align: center;
  margin-bottom: 12rem;
}
.servicesGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);

  background-color: rgba(153, 153, 153, 0.1);
}
.gridTextbox {
  padding: 5rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.gridHeading {
  font-size: 3.6rem;
  font-weight: 700;

  position: relative;
  z-index: 10;
}
.gridParagraph {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.7;
  width: 70%;
}
.gridImg {
  min-height: 50rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  flex: 0 0 50%;
}
.gridButton {
  align-self: flex-start;
}
.gridHeading::after {
  content: "";
  display: block;
  height: 5px;
  background-image: linear-gradient(to right, #262626cc, #1515152c);
  transform: skew(15deg);
  width: 10%;
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  z-index: -1;
}

.gridDivider {
  /* background-color: var(--color-primary-light); */
  padding: 12rem;
}

.gridDividerHeading {
  font-size: 3.2rem;
  font-weight: 800;
  margin-bottom: 8rem;

  position: relative;
}
.gridDividerHeading::after {
  content: "";
  display: block;
  height: 5px;
  width: 25%;
  transform: skew(15deg);
  position: absolute;
  bottom: -1rem;
  left: 0;
}

.gridDividerFlex {
  display: flex;
}
.gridGallery {
  flex: 1 0 60%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 1rem;
  padding: 1rem;
}
.gridCellImg {
  max-width: 50rem;
  overflow: hidden;
  transition: all 0.2s;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.gridCell {
  transition: all 0.5s;
}
.gridCell:hover {
  transform: scale(1.02);
}

.gridDividerTextbox {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  gap: 2rem;
}
.gridDividerHeadingSecondary {
  font-size: 2.4rem;
  font-weight: 800;
}
.gridDividerParagraph {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.7;
}
.gridDividerButton,
.gridDividerButton:link,
.gridDividerButton:visited {
  align-self: flex-start;
  margin-top: 2rem;
}
.gridDividerHeadingTertiary {
  font-size: 2rem;
  font-weight: 800;
  margin-top: 2rem;
}
.gridDividerIcon {
  height: 24px;
  width: 24px;
  color: green;
}
.gridDividerFlexbox {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: nowrap;
  font-size: 1.6rem;
  font-weight: 500;
}
.gridDividerParagraph2 {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.7;
  margin-top: 4rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.3);

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
}
.modalFlex {
  display: flex;
}
.modalImg {
  flex: 1 1 50%;
  min-width: 40rem;
  min-height: 40rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.modalTextbox {
  flex: 1 1 50%;
  padding: 5rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.modalHeading {
  font-size: 2.4rem;
  font-weight: 800;

  position: relative;
}
.modalHeading::after {
  content: "";
  display: block;
  height: 5px;
  width: 100%;
  background-image: var(--gradient-primary);
  transform: skew(15deg);
  width: 20%;
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  z-index: -1;
}
.modalParagraph {
  font-size: 1.4rem;
  line-height: 1.7;
  padding: 1rem;
}
.modalButton {
  font-size: inherit;
  color: var(--color-text);
  background-color: transparent;
  border: none;

  cursor: pointer;

  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 999;
}
.modalIcon {
  height: 24px;
  width: 24px;
  color: currentColor;
}

.gallery {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 8rem 0;
}
.galleryHeading {
  font-size: 4rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 8rem;
}
.gallerySlide {
  min-height: 80rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.galleryParagraph {
  font-size: 2rem;
  font-weight: 500;
  margin-top: 2rem;
}
.highlight {
  font-weight: 700;
}
.contactWrapper {
  padding: 8rem 0;
}

.formWrapper {
  display: flex;
  flex-direction: column;
}
.ctaForm {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 12rem;
}

.ctaFormInputs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.formInput {
  width: 100%;
  font-family: inherit;
  padding: 0.5rem 1rem;
}

.formInput:focus,
.formTextarea:focus {
  outline: 1px solid var(--color-secondary);
}

.formTextarea {
  font-family: inherit;
  padding: 0.5rem 1rem;
  resize: none;
}

.contactFormButton {
  align-self: center;
  margin-top: 2rem;
  padding: 1rem 2rem;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 300;
  transition: all 0.1s ease-out;

  cursor: pointer;
}
.contactParagraph {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 2rem;
}
.contactHeading {
  font-size: 3.2rem;
  text-align: center;
  margin-bottom: 8rem;
}

.copyright {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.8rem 0;
  text-align: center;
  color: var(--color-white);
  font-size: 1.2rem;
  font-weight: 200;
}
.copyrightLink:link,
.copyrightLink:visited {
  text-decoration: none;
  color: var(--color-primary-dark);
}

/* ****************************************************
*******************************************************
*******************************************************
*******************************************************
*******************************************************
*******************************************************
*******************************************************
*******************************************************
*******************************************************
******************************************************* */

/* 1400px */
@media only screen and (max-width: 87.5em) {
  .heroHeading {
    font-size: 4.6rem;
  }
  .gridParagraph {
    font-size: 1.8rem;
    width: 95%;
  }
  .gridGallery {
    flex: 1 0 65%;
  }
  .modalTextbox {
    padding: 2rem;
  }
}

/* 1304px */
@media only screen and (max-width: 81.5em) {
  html {
    font-size: 56.25%;
  }
  .gridDividerFlex {
    flex-direction: column;
  }
  .modalFlex {
    flex-direction: column;
  }
}

/* 904px */
@media only screen and (max-width: 56.5em) {
  html {
    font-size: 50%;
  }
  .navigationWrapper {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding-bottom: 4rem;
  }
  .navigationHeading {
    display: none;
  }
  .navigationLogo {
    max-width: 10rem;
  }
  .introParagraph,
  .gridParagraph {
    font-size: 1.6rem;
  }
  .navigationNav {
    margin: 0;
  }
  .galleryParagraph {
    padding: 1rem;
  }
}

/* 760px */
@media only screen and (max-width: 47.5em) {
  .heroHeading {
    font-size: 3.6rem;
  }
  .introWrapper {
    flex-direction: column;
    align-items: stretch;
    padding: 0 4rem;
    padding-bottom: 4rem;
    gap: 4rem;
  }
  .introParagraph {
    padding: 0;
    font-size: 1.8rem;
  }
  .introButton {
    display: none;
  }
  .ctaForm {
    padding: 0 4rem;
  }
  .servicesGrid {
    grid-template-columns: 1fr;
  }
  #gridCell1 {
    order: 1;
  }
  #gridCell2 {
    order: 2;
  }
  #gridCell3 {
    order: 4;
  }
  #gridCell4 {
    order: 3;
  }
  #gridCell5 {
    order: 5;
  }
  #gridCell6 {
    order: 6;
  }
  .gridTextbox {
    margin: auto;
    align-items: center;
  }
  .gridHeading::after {
    width: 100%;
  }
  .gridParagraph {
    font-size: 1.8rem;
  }
  .gridButton {
    margin-left: 2rem;
  }
  .gridDividerHeading {
    font-size: 2.8rem;
  }
  .gridDividerHeadingSecondary {
    font-size: 2.2rem;
  }
  .gridDividerParagraph {
    font-size: 1.6rem;
  }
  .gridDividerHeadingTertiary {
    font-size: 1.8rem;
  }
  .galleryParagraph {
    text-align: center;
  }
  .gridDivider {
    padding: 4rem;
  }
  .gridDividerHeading {
    padding: 4rem 2rem;
  }
  .modalImg {
    min-width: 40rem;
    min-height: 25rem;
    background-position: bottom;
  }
  .modalTextbox {
    overflow-y: scroll;
  }
}

/* 592px */
@media only screen and (max-width: 37em) {
  .heroHeading {
    font-size: 3.2rem;
  }
  .navigationLogo {
    display: none;
  }
  .introParagraph {
    font-size: 1.6rem;
  }
  .gridDividerFlex {
    align-items: stretch;
  }
  .galleryParagraph {
    font-size: 1.6rem;
  }
  .navigationButton {
    font-size: 1.6rem;
  }
  .navigationWrapper {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .navigationDropdown {
    transform: translateX(-50%) translateY(-100%);
    gap: 2rem;
    list-style: none;
  }
  .navigationDropdownOpen {
    transform: translateX(-50%) translateY(0);
  }
  .ctaFormInputs {
    flex-direction: column;
    gap: 1.5rem;
  }
  .gridParagraph {
    font-size: 1.6rem;
    align-self: flex-start;
  }
  .gridButton {
    margin-left: 0;
  }
  .heroHeading {
    padding: 8rem 4rem;
  }
  .contactBarLink:link,
  .contactBarLink:visited {
    font-size: 1.4rem;
  }
  .heroButton {
    font-size: 1.4rem;
    white-space: nowrap;
    padding: 1rem 3rem;
  }
  .gridDivider {
    padding: 4rem 2rem;
  }
  .copyright {
    padding: 0.8rem 0.5rem;
  }
  .galleryHeading,
  .servicesHeading {
    font-size: 3.8rem;
  }
  .modalButton {
    color: var(--color-black);
  }
  .navigationList {
    gap: 1.2rem;
  }
}
